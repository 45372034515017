import { createApp } from 'vue'
import App from './App.vue'

// 大屏响应式解决方案
import autofit from 'autofit.js'
// 引入vue-router
import Router from "@/router";
// 引入pinia
import { createPinia } from "pinia";

createApp(App).use(Router).use(createPinia).use(autofit).mount('#app')
