<template>
  <router-view></router-view>
</template>

<script setup>
/**
 * 依赖引入
 */
// import router from "@/router"
import autofit from "autofit.js";
import { onMounted, onBeforeMount } from "vue";
import router from "@/router";
// import BodyView from "./views/BodyView/BodyView.vue";

/**
 * 生命周期函数
 */
onMounted(() => {
  router.push("/loading");
  autofit.init({
    dh: 1080,
    dw: 1920,
    el: "#app",
    resize: true,
  });


  // Loading 加载
  setTimeout(() => {
    router.push("/main");
  }, 5000);
});

onBeforeMount(() => {
  autofit.off();
});
</script>

<style>
@import url("@/styles/CommonCss/common.css");
</style>
