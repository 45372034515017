// 引入vuerouter的相关包
import { createWebHashHistory, createRouter } from "vue-router";

// 创建实例化对象
const history = createWebHashHistory();
const router = createRouter({
  history,
  routes: [
    {
      name: "加载页面",
      path: "/loading",
      component: () => import("@/views/LoadingView/LoadingView"),
    },
    {
      name: "主体",
      path: "/main",
      redirect: "/scxl",
      component: () => import("@/views/MainView/MainView"),
      children: [
        {
          name: "生产效率",
          path: "/scxl",
          component: () => import("@/views/BodyView/BodyView"),
        },
        {
          name: "能耗分析",
          path: "/",
          component: () => import("@/views/YiliaoView/YiliaoView"),
        },
        {
          name: "质量控制",
          path: "/",
          component: () => import("@/views/YiliaoView/YiliaoView"),
        },
      ],
    },
  ],
});

// 把router路径暴露出去
export default router;
